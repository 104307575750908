import { createSlice } from "@reduxjs/toolkit";
import { MAX_ACCURACY, MIN_ACCURACY } from "../../constants";

import { Direction } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";

const formatDate = (date: Date | string) => new Date(date).toISOString();

export interface ControlsState {
  showBusStops: boolean;
  showPickedUp: boolean;
  stopTime: number;
  date: string;
  accuracy: number;
  direction: Direction;
}

let initialState: ControlsState = {
  showBusStops: true,
  showPickedUp: true,
  stopTime: 60,
  date: formatDate(new Date()),
  accuracy: 100,
  direction: Direction.PickUp,
};

const cachedState = localStorage.getItem("redux_cache_for_controls");
try {
  const cachedStateJson = JSON.parse(cachedState || "");

  if (cachedStateJson) {
    initialState = {
      ...initialState,
      ...cachedStateJson,
      date: formatDate(new Date()),
    };
  }
} catch (_) {}

export const controlsSlice = createSlice({
  name: "controls",
  initialState,
  reducers: {
    setShowBusStops: (
      state,
      action: PayloadAction<ControlsState["showBusStops"]>
    ) => {
      state.showBusStops = action.payload;
    },
    setShowPickedUp: (
      state,
      action: PayloadAction<ControlsState["showPickedUp"]>
    ) => {
      state.showPickedUp = action.payload;
    },
    setStopTime: (state, action: PayloadAction<ControlsState["stopTime"]>) => {
      state.stopTime = action.payload;
    },
    setDate: (state, action: PayloadAction<ControlsState["date"]>) => {
      state.date = formatDate(action.payload);
    },
    setAccuracy: (state, action: PayloadAction<ControlsState["accuracy"]>) => {
      state.accuracy = Math.min(
        Math.max(action.payload, MIN_ACCURACY),
        MAX_ACCURACY
      );
    },
    setDirection: (
      state,
      action: PayloadAction<ControlsState["direction"]>
    ) => {
      state.direction = action.payload;
    },
  },
});

export const {
  setShowBusStops,
  setShowPickedUp,
  setStopTime,
  setDate,
  setAccuracy,
  setDirection,
} = controlsSlice.actions;

export default controlsSlice.reducer;
