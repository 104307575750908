import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import controls from "./module/controls";
import busAttendances from "./module/busAttendances";
import locations from "./module/locations";

const customMiddleware = createListenerMiddleware();

customMiddleware.startListening({
  predicate: () => true,
  effect: (_action, api) => {
    const state = api.getState() as Record<string, any>;

    for (const key in state) {
      localStorage.setItem(
        `redux_cache_for_${key}`,
        JSON.stringify(state[key])
      );
    }
  },
});

export const store = configureStore({
  middleware(getDefaultMiddleware) {
    const defaultMiddleware = getDefaultMiddleware();
    defaultMiddleware.push(customMiddleware.middleware);
    return defaultMiddleware;
  },
  reducer: {
    controls,
    busAttendances,
    locations,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
