import { Marker } from "@react-google-maps/api";
import { colors } from "../../utils";

import type { LatLng } from "../../types";

type BusStopMarkerProps = {
  stopPoint: LatLng;
  onClick: () => void;
  isSelected: boolean;
};

const BusStopMarker = (props: BusStopMarkerProps) => {
  return (
    <Marker
      position={props.stopPoint}
      onClick={props.onClick}
      // style={{ padding: props.isSelected ? 0 : 4 }}
      icon={{
        path: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z",
        fillColor: colors.red1,
        fillOpacity: 1,
        scale: props.isSelected ? 0.075 : 0.05,
        strokeColor: "white",
        strokeWeight: props.isSelected ? 2 : 0,
      }}
    />
  );
};

export default BusStopMarker;
