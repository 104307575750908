import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { getLocations as getLocationsApi } from "../../services/firebase-db.service";
import { isSameLocation } from "../../services/location.service";
import { keyBy } from "../../utils";

import type { ChildData, DetailedLocation, Location } from "../../types";
import type { RootState } from "../store";

export interface LocationsState {
  locations: Array<Location>;
  locationsLoading: boolean;
  latestLocations: Array<Location>;
  latestLocationsLoading: boolean;
}

const initialState: LocationsState = {
  locations: [],
  locationsLoading: false,
  latestLocations: [],
  latestLocationsLoading: false,
};

export const getBusAttendanceLocations = createAsyncThunk(
  "locations/getBusAttendanceLocations",
  (busAttendanceId: string) => getLocationsApi({ busAttendanceId })
);

const halfDayMilliseconds = 12 * 60 * 60 * 1000;

export const getLatestLocation = createAsyncThunk(
  "locations/getLatestLocation",
  (_: void, { getState }) => {
    const state = getState() as RootState;
    const timestampValue = Date.now() - halfDayMilliseconds;

    if (
      new Date(state.controls.date).getTime() <
      timestampValue - halfDayMilliseconds
    ) {
      return [];
    }

    return Promise.all(
      state.busAttendances.busAttendances.map((busAttendance) =>
        getLocationsApi({
          busAttendanceId: busAttendance.busAttendanceId,
          limit: 1,
          sort: { timestamp: "desc" },
        })
      )
    );
  }
);

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusAttendanceLocations.pending, (state) => {
        state.locationsLoading = true;
      })
      .addCase(getBusAttendanceLocations.fulfilled, (state, action) => {
        state.locationsLoading = false;
        state.locations = action.payload;
      })
      .addCase(getBusAttendanceLocations.rejected, (state) => {
        state.locationsLoading = false;
        state.locations = [];
      })
      .addCase(getLatestLocation.pending, (state) => {
        state.latestLocationsLoading = true;
      })
      .addCase(getLatestLocation.fulfilled, (state, action) => {
        state.latestLocationsLoading = false;
        state.latestLocations = action.payload.flat();
      })
      .addCase(getLatestLocation.rejected, (state, action) => {
        state.latestLocationsLoading = false;
        state.latestLocations = [];
      });
  },
});

const getKeyFromLatLng = (l: Location) => `${l.lat}${l.lng}`;

export const selectRoundedAndSortedLocations = createSelector(
  (state: RootState) => state.locations.locations,
  (state: RootState) => state.busAttendances.selectedBusAttendanceChildren,
  (state: RootState) => state.controls.stopTime,
  (state: RootState) => state.controls.accuracy,
  (locations, selectedBusAttendanceChildren, stopTime, accuracy) => {
    const sortedByTime = [...locations].sort((a, b) =>
      a.timestamp > b.timestamp ? 1 : -1
    );
    const childrenByLocation: Record<string, Array<ChildData>> = {};
    const rawLocations = sortedByTime.reduce((acc, item, currentIndex) => {
      const uniqueLocations = Object.values(acc).flat();
      const locationChildren = selectedBusAttendanceChildren.filter((child) =>
        isSameLocation(
          {
            lat: Number(child.location.latitude),
            lng: Number(child.location.longitude),
          },
          item
        )
      );
      if (item.accuracy && item.accuracy > accuracy) {
        return acc;
      }
      const isPrevSameLocation = isSameLocation(
        item,
        sortedByTime[currentIndex - 1]
      );
      const prevKey = uniqueLocations.find(
        (i) => i.index === currentIndex - 1
      )?.key;
      if (isPrevSameLocation && prevKey) {
        acc[prevKey].push({ ...item, key: prevKey, index: currentIndex });
        childrenByLocation[prevKey].push(...locationChildren);
      } else {
        const key = getKeyFromLatLng(item);
        acc[key] = [{ ...item, key, index: currentIndex }];
        childrenByLocation[key] = locationChildren;
      }

      return acc;
    }, {} as Record<string, Array<Location & { key: string; index: number }>>);

    return Object.entries(rawLocations).map(([key, locationPoints], index) => {
      const firstPoint = locationPoints[0];
      const lastPoint = locationPoints[locationPoints.length - 1];

      const children = Object.values(
        keyBy(childrenByLocation[key] || [], (child) => child.id)
      );
      const locationItem: DetailedLocation = {
        ...locationPoints[Math.floor(locationPoints.length / 2)],
        startedAt: firstPoint.timestamp,
        endedAt: lastPoint.timestamp,
        isStop: false,
        index,
        children,
      };

      const stoppedTime = lastPoint.timestamp - firstPoint.timestamp;
      if (stoppedTime > stopTime * 1000) {
        locationItem.isStop = true;
      }

      return locationItem;
    });
  }
);

export default locationsSlice.reducer;
