import type { LatLng } from "../types";

/**
 * Returns a distance between two points in metres
 * ([source](https://www.movable-type.co.uk/scripts/latlong.html))
 */
export function distanceBetween(
  l1: LatLng | undefined,
  l2: LatLng | undefined
) {
  if (!l1 || !l2) {
    return 0;
  }
  const R = 6371e3;
  const φ1 = (l1.lat * Math.PI) / 180; // φ, λ in radians
  const φ2 = (l2.lat * Math.PI) / 180;
  const Δφ = ((l2.lat - l1.lat) * Math.PI) / 180;
  const Δλ = ((l2.lng - l1.lng) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;

  return d;
}

// export const distanceBetween = (
//   l1: LatLng | undefined,
//   l2: LatLng | undefined
// ) =>
//   !l1 || !l2
//     ? 0
//     : Math.pow(
//         Math.pow(l2?.lat - l1?.lat, 2) + Math.pow(l2?.lng - l1?.lng, 2),
//         0.5
//       );

export const isSameLocation = (
  l1: LatLng | undefined,
  l2: LatLng | undefined
) => distanceBetween(l1, l2) < 50;

// export const isSameLocation = (
//   l1: LatLng | undefined,
//   l2: LatLng | undefined
// ) => l1?.lat === l2?.lat && l1?.lng === l2?.lng;
