import Row from "react-bootstrap/Row";
import ChildCard from "./ChildCard";
import useAppSelector from "../hooks/useAppSelector";
import useAppDispatch from "../hooks/useAppDispatch";
import { setSelectedChildren } from "../redux/module/busAttendances";

import type { ChildData } from "../types";

const SelectedChildrenDetails = () => {
  const dispatch = useAppDispatch();
  const selectedChildren = useAppSelector(
    (state) => state.busAttendances.selectedChildren
  );
  const busAttendances = useAppSelector(
    (state) => state.busAttendances.busAttendances
  );
  const selectedBusAttendance = useAppSelector(
    (state) => state.busAttendances.selectedBusAttendance
  );

  if (!selectedChildren.length) {
    return null;
  }

  const handleClose = (child: ChildData) => {
    dispatch(
      setSelectedChildren(
        selectedChildren.filter((item) => item.id !== child.id)
      )
    );
  };

  return (
    <Row>
      <div
        style={{ height: 1, backgroundColor: "lightgray", marginBlock: 8 }}
      />
      {selectedChildren.map((child) => (
        <ChildCard
          key={child.id}
          child={child}
          onClose={() => handleClose(child)}
          busAttendance={busAttendances.find(
            (busAttendance) =>
              selectedBusAttendance?.busAttendanceId !==
                child.busAttendanceId &&
              busAttendance.busAttendanceId === child.busAttendanceId
          )}
        />
      ))}
    </Row>
  );
};

export default SelectedChildrenDetails;
