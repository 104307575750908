import { Direction } from "./types";

export const GOOGLE_MAPS_API_KEY = process.env
  .REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL as string;

export const MIN_ACCURACY = 10;
export const MAX_ACCURACY = 200;

export const FIREBASE_LOCATION_COLLECTION =
  process.env.REACT_APP_FIREBASE_LOCATION_COLLECTION || "locations";

export const REACT_APP_FIREBASE_STATS_COLLECTION =
  process.env.REACT_APP_REACT_APP_FIREBASE_STATS_COLLECTION || "stats";

export const directionText = {
  [Direction.PickUp]: "Morning route",
  [Direction.DropOff]: "Afternoon route",
};
