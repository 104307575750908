import { useEffect, useState } from "react";
import { postStatsToFDB } from "../services/firebase-db.service";

const initialStats = {
  startTimestamp: 0,
  requested: 0,
  succeeded: 0,
  readFromCache: 0,
  requestFailed: 0,
  requestFailedWithNoData: 0,
};

function useRecordStats() {
  const [stats, setStats] = useState(initialStats);

  useEffect(() => {
    const stats = localStorage.getItem("DIRECTIONS_STATS");
    try {
      const statsJson = stats && JSON.parse(stats);
      statsJson && setStats(statsJson);
    } catch (err) {}
  }, []);

  const recordStats = (statsUpdate: Partial<typeof stats>) => {
    setStats((oldStats) => {
      const newStats = { ...oldStats };
      for (const key in statsUpdate) {
        const statsKey = key as keyof typeof stats;
        const value = statsUpdate[statsKey];
        if (typeof value !== "number") {
          continue;
        }
        newStats[statsKey] += value;
      }
      if (!newStats.startTimestamp) {
        newStats.startTimestamp = Date.now();
      }
      if (newStats.requested === 500) {
        postStatsToFDB({ ...newStats });
        for (const key in newStats) {
          newStats[key as keyof typeof stats] = 0;
        }
      }
      localStorage.setItem("DIRECTIONS_STATS", JSON.stringify(newStats));
      return newStats;
    });
  };

  return recordStats;
}

export default useRecordStats;
