import { useRouteError } from "react-router-dom";

function RouterErrorElement() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <span className="h-2 text-danger">Something went wrong</span>
    </div>
  );
}

export default RouterErrorElement;
