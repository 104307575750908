import { Marker } from "@react-google-maps/api";
import { colors } from "../../utils";

import type { LatLng } from "../../types";

type ChildMarkerProps = {
  position?: LatLng;
  onClick: () => void;
  notOnBus: boolean;
};

const ChildMarker = (props: ChildMarkerProps) => {
  if (!props.position) {
    return null;
  }

  return (
    <Marker
      position={props.position}
      onClick={props.onClick}
      icon={{
        path: "m5.0477 6.6809c1.2196 0 2.2083-.9306 2.2083-2.0785 0-1.1479-.9887-2.0785-2.2083-2.0785-1.2196 0-2.2083.9306-2.2083 2.0785 0 1.1479.9887 2.0785 2.2083 2.0785zm-1.5774.8908c-.3165 0-.612.1489-.7875.3966l-2.5238 3.5631c-.2899.4093-.1724.9624.2625 1.2353.4349.2729 1.0225.1622 1.3125-.247l.7899-1.1152v5.3718c0 .492.4237.8908.9464.8908.5227 0 .9464-.3988.9464-.8908v-2.9692h1.2619v2.9692c0 .492.4237.8908.9464.8908.5227 0 .9464-.3988.9464-.8908v-5.3718l.7899 1.1152c.2899.4092.8775.5199 1.3124.247.4349-.2729.5525-.826.2625-1.2353l-2.5238-3.5631c-.1755-.2477-.471-.3966-.7874-.3966h-3.1548zm12.9361-7.3225c0-.1829-.2149-.3034-.401-.2247l-2.1228.8972-2.1228-.8972c-.1861-.0787-.401.0417-.401.2247v1.3452c0 .183.2149.3033.401.2247l2.1228-.8973 2.1228.8973c.1861.0786.401-.0417.401-.2247v-1.3452zm-2.5238 6.0495c1.2196 0 2.2083-.9629 2.2083-2.1508 0-1.1878-.9887-2.1508-2.2083-2.1508-1.2196 0-2.2083.9629-2.2083 2.1508 0 1.1878.9887 2.1508 2.2083 2.1508zm-1.5774.9218c-.3165 0-.6119.154-.7875.4104l-2.5238 3.687c-.2899.4236-.1724.9959.2625 1.2783.4349.2824 1.0225.1679 1.3124-.2556l.79-1.154v1.1035l-1.2619 2.458h1.2619v1.9972c0 .509.4237.9217.9464.9217.5227 0 .9464-.4127.9464-.9217v-1.9972h1.262v1.9972c0 .509.4236.9217.9464.9217.5227 0 .9464-.4127.9464-.9217v-1.9972h1.2619l-1.2619-2.458v-1.1035l.7899 1.154c.2899.4235.8775.538 1.3125.2556.4349-.2824.5524-.8547.2624-1.2783l-2.5238-3.687c-.1755-.2564-.471-.4104-.7874-.4104h-3.1548z",
        fillColor: props.notOnBus ? colors.red1 : colors.blue2,
        fillOpacity: 1,
        scale: 2,
        strokeWeight: 0,
      }}
    />
  );
};

export default ChildMarker;
