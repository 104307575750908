import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import RefreshIcon from "./icons/RefreshIcon";
import useAppDispatch from "../hooks/useAppDispatch";
import useAppSelector from "../hooks/useAppSelector";
import {
  setSelectedBus,
  setSelectedBusAttendance,
  setSelectedChildren,
  setSelectedLocation,
} from "../redux/module/busAttendances";
import {
  // setAccuracy,
  setDate,
  setDirection,
  setShowBusStops,
  setShowPickedUp,
  setStopTime,
} from "../redux/module/controls";
import { directionText } from "../constants";

import { BusAttendanceStatus, ChildData, Direction } from "../types";

const autocompleteOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  keys: ["name"], // TODO: declare fields for filtering
};
const autocompleteStyling = {
  boxShadow: "none",
  borderRadius: "0.375rem",
  border: "1px solid #ced4da",
};

type ControlsProps = {
  onRefresh: () => void;
};

const Controls = (props: ControlsProps) => {
  const dispatch = useAppDispatch();
  const [childSearchTerm, setChildSearchTerm] = useState("");
  const showBusStops = useAppSelector((state) => state.controls.showBusStops);
  const showPickedUp = useAppSelector((state) => state.controls.showPickedUp);
  const stopTime = useAppSelector((state) => state.controls.stopTime);
  const date = useAppSelector((state) => state.controls.date);
  const direction = useAppSelector((state) => state.controls.direction);
  // const accuracy = useAppSelector((state) => state.controls.accuracy);
  const busAttendances = useAppSelector(
    (state) => state.busAttendances.busAttendances
  );
  const selectedBusAttendance = useAppSelector(
    (state) => state.busAttendances.selectedBusAttendance
  );
  const childrenWithBusAttendance = useAppSelector(
    (state) => state.busAttendances.childrenWithBusAttendance
  );
  const childrenNotOnBus = useAppSelector(
    (state) => state.busAttendances.childrenNotOnBus
  );
  const selectedChildren = useAppSelector(
    (state) => state.busAttendances.selectedChildren
  );
  const selectedLocation = useAppSelector(
    (state) => state.busAttendances.selectedLocation
  );

  const handleBusAttendanceChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = e.target.value;
    const busAttendance =
      id && busAttendances.find((item) => item.busAttendanceId === id);

    dispatch(setSelectedBusAttendance(busAttendance || null));
  };

  const handleDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    dispatch(setDate(date.toISOString()));
    dispatch(setSelectedBusAttendance(null));
    dispatch(setSelectedLocation(null));
    dispatch(setSelectedBus(null));
  };

  const handleChildSelect = (child: ChildData) => {
    setChildSearchTerm("");
    const selectedLocationChildIndex = (
      selectedLocation?.children || []
    ).findIndex((item) => item.id === child.id);
    if (selectedLocation?.children[selectedLocationChildIndex]) {
      const newSelectedLocation = {
        ...selectedLocation,
        children: [...selectedLocation.children],
      };

      newSelectedLocation.children[selectedLocationChildIndex] = {
        ...child,
        highlightTimestamp: Date.now(),
      };
      dispatch(setSelectedLocation(newSelectedLocation));
      return;
    }
    const existingIndex = selectedChildren.findIndex(
      (item) => item.id === child.id
    );
    const newSelectedChildren = [...selectedChildren];
    if (selectedChildren[existingIndex]) {
      newSelectedChildren.splice(existingIndex, 1);
      newSelectedChildren.splice(existingIndex, 0, {
        ...child,
        highlightTimestamp: Date.now(),
      });
    } else {
      newSelectedChildren.push(child);
    }

    dispatch(setSelectedChildren(newSelectedChildren));
  };

  const handleDirectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDirection(e.target.value as Direction));
  };

  return (
    <>
      <Button onClick={props.onRefresh} className="mb-2 w-100" variant="light">
        <RefreshIcon size={18} />
        <span className="ms-2">Refresh data</span>
      </Button>
      <DatePicker
        onChange={handleDateChange}
        selected={new Date(date)}
        dateFormat="MM/dd/yyyy"
        className="form-control mb-2"
      />
      <Form.Select
        className="mb-2"
        onChange={handleBusAttendanceChange}
        value={selectedBusAttendance?.busAttendanceId || ""}
      >
        <option value="">All</option>
        {busAttendances
          .filter((item) => item.scheduleDirection === direction)
          .map((busAttendance) => (
            <option
              key={busAttendance.busAttendanceId}
              value={busAttendance.busAttendanceId}
            >
              {busAttendance.busRoute}
              {busAttendance.status === BusAttendanceStatus.Cancelled &&
                " - cancelled -"}
            </option>
          ))}
      </Form.Select>
      <ReactSearchAutocomplete
        items={[...childrenWithBusAttendance, ...childrenNotOnBus]}
        fuseOptions={autocompleteOptions}
        styling={autocompleteStyling}
        onSelect={handleChildSelect}
        inputDebounce={100}
        inputSearchString={childSearchTerm}
        onSearch={setChildSearchTerm}
      />
      <Row className="my-2">
        <Col>
          <Form.Check
            id="direction-PickUp"
            type="radio"
            name="direction"
            label={directionText[Direction.PickUp]}
            checked={direction === Direction.PickUp}
            value={Direction.PickUp}
            onChange={handleDirectionChange}
          />
        </Col>
        <Col>
          <Form.Check
            id="direction-DropOff"
            type="radio"
            name="direction"
            label={directionText[Direction.DropOff]}
            checked={direction === Direction.DropOff}
            value={Direction.DropOff}
            onChange={handleDirectionChange}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Check
            id="show-bus-stops"
            checked={showBusStops}
            onChange={(e) => dispatch(setShowBusStops(e.target.checked))}
            label="Show bus stops"
          />
        </Col>
        <Col>
          <Form.Check
            id="show-picked-up-places"
            checked={showPickedUp}
            onChange={(e) => dispatch(setShowPickedUp(e.target.checked))}
            label="Show picked up"
          />
        </Col>
      </Row>
      <Row className="mb-2 align-items-center">
        <Col xxl="auto" xs="auto">
          <Form.Label htmlFor="stop-time">Stop Time:</Form.Label>
        </Col>
        <Col>
          <Form.Control
            id="stop-time"
            type="number"
            value={stopTime}
            onChange={(e) => dispatch(setStopTime(+e.target.value))}
            disabled={!showBusStops}
          />
        </Col>
      </Row>
    </>
  );
};

export default Controls;
