import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LocationTracking from "./pages/LocationTracking";
import NotAllowed from "./pages/NotAllowed";
import Stats from "./pages/Stats";
import RouterErrorElement from "./RouterErrorElement";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LocationTracking />,
    errorElement: <RouterErrorElement />,
  },
  {
    path: "/not-allowed",
    element: <NotAllowed />,
  },
  {
    path: "/stats",
    element: <Stats />,
  },
]);

console.log("Tracking app version: 1.0.0");

function App() {
  return <RouterProvider router={router} />;
}

export default App;
