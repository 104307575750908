export type User = {
  id: string;
  username: string;
};

export enum Direction {
  PickUp = "pick_up",
  DropOff = "drop_off",
}

export enum BusAttendanceStatus {
  Started = "started",
  Cancelled = "cancelled",
  Completed = "completed",
}

export type BusAttendance = {
  busAttendanceId: string;
  busRoute: string;
  busName: string;
  profilePicture: string;
  scheduleDirection: Direction;
  status: BusAttendanceStatus;
  startedAt: string;
  completedAt: string;
  busAttendant: {
    id: string;
    name: string;
  };
  busDriver: {
    id: string;
    name: string;
  };
  location: {
    latitude: string;
    longitude: string;
  };
};

export type Location = {
  id: string;
  busAttendanceId: string;
  lat: number;
  lng: number;
  timestamp: number;
  accuracy?: number;
  speed?: number;
  heading?: number;
};

export type DetailedLocation = {
  index: number;
  startedAt: number;
  endedAt: number;
  isStop: boolean;
  children: Array<ChildData>;
} & Location;

export type LatLng = {
  lat: number;
  lng: number;
};

export type BoundingBox = {
  northEast: LatLng;
  southWest: LatLng;
};

export enum BusAttendanceChildStatus {
  PickedUp = "picked_up",
  LoadedOnBus = "loaded_on_bus",
  DroppedOff = "dropped_off",
}

/** Date in format YYYY-MM-DD */
type DateString = string;
/** Date in format YYYY-MM-DD HH:mm:ss */
type DateTimeString = string;

export type ChildData = {
  id: string;
  fname: string;
  lname: string;
  age: string;
  parentEmail: string;
  nfcCardUid: string;
  classTeacher: string;
  profilePicture: string;
  father: {
    name: string;
    phone: string;
  };
  mother: {
    name: string;
    phone: string;
  };
  home: {
    phone: string;
    address: string;
    latitude: string;
    longitude: string;
  };
  class: {
    id: string;
    name: string;
  };
  branch: {
    id: string;
    name: string;
    color: string;
  };
  location: {
    latitude: string;
    longitude: string;
  };
  /** Combination of `fname` and `lname` */
  name: string;
  highlightTimestamp?: number;
} & (
  | {
      busAttendanceId: string;
      status: BusAttendanceChildStatus;
      time: DateTimeString;
      date?: undefined;
      notOnBus: false;
    }
  | {
      busAttendanceId: string | null;
      status?: undefined;
      time?: undefined;
      date: DateString;
      notOnBus: true;
    }
);

export type StatsItem = {
  coll: "stats-local" | "stats-debug" | "stats-prod";
  source: string;
  startTimestamp: number;
  requested: number;
  succeeded: number;
  readFromCache: number;
  requestFailed: number;
  requestFailedWithNoData: number;
};

export type AppInfoItem = {
  coll: "local" | "debug" | "prod";
  id: string;
  updatedAt: number;
  userId: string;
  userName: string;
  userType: "teacher" | "bus";
  version: string;
};
