import { useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InfoItem from "./InfoItem";
import CloseIcon from "./icons/CloseIcon";
import ArrowRightIcon from "./icons/ArrowRightIcon";
import useAppDispatch from "../hooks/useAppDispatch";
import { setSelectedBusAttendance } from "../redux/module/busAttendances";
import girlAvatar from "../assets/images/girlAvatar.png";
import "./child-card.css";

import { BusAttendance, BusAttendanceChildStatus, ChildData } from "../types";

type ChildCardProps = {
  child: ChildData;
  busAttendance?: BusAttendance;
  onClose?: () => void;
};

const attendanceTextByStatus = {
  [BusAttendanceChildStatus.PickedUp]: "Picked up",
  [BusAttendanceChildStatus.LoadedOnBus]: "Loaded on bus",
  [BusAttendanceChildStatus.DroppedOff]: "Dropped off",
};

const ChildCard = (props: ChildCardProps) => {
  const dispatch = useAppDispatch();
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.child.highlightTimestamp) {
      return;
    }

    rowRef.current?.classList.add("highlighted-container");
    rowRef.current?.scrollIntoView();

    const removeHighlightClass = () => {
      rowRef.current?.classList.remove("highlighted-container");
    };

    setTimeout(removeHighlightClass, 2000);
    return removeHighlightClass;
  }, [props.child.highlightTimestamp]);

  return (
    <div className="child-card-container">
      <Row ref={rowRef} className="my-2 ms-0 me-1 p-1 border rounded">
        <Col xxl="auto" xs="auto" className="p-1">
          <img
            width={41}
            height={51}
            alt="child-profile"
            src={props.child.profilePicture || girlAvatar}
            style={{ borderRadius: 6 }}
          />
        </Col>
        <Col className="px-1">
          <div>
            <span>
              {props.child.fname} {props.child.lname}
            </span>
          </div>
          {props.child.status && (
            <div>
              <InfoItem
                title={
                  attendanceTextByStatus[props.child.status] ||
                  props.child.status
                }
                info={new Date(props.child.time).toLocaleTimeString()}
              />
            </div>
          )}
          {props.busAttendance && (
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.busAttendance &&
                dispatch(setSelectedBusAttendance(props.busAttendance))
              }
            >
              <small className="me-1">{props.busAttendance.busRoute}</small>
              <ArrowRightIcon />
            </div>
          )}
        </Col>
        <Col xxl="auto" xs="auto" className="pe-1">
          <div className="d-flex align-items-center flex-column h-100 justify-content-evenly flex-grow-1">
            {props.onClose && (
              <div
                className="d-flex justify-content-center"
                onClick={props.onClose}
                style={{ margin: -3 }}
              >
                <CloseIcon size={24} />
              </div>
            )}
            <div
              style={{
                backgroundColor: props.child.branch.color,
                width: 18,
                height: 18,
                borderRadius: 9,
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChildCard;
