import { useMemo } from "react";
import Row from "react-bootstrap/Row";
import InfoItem from "./InfoItem";
import ChildCard from "./ChildCard";
import useAppSelector from "../hooks/useAppSelector";

const SelectedLocationDetails = () => {
  const selectedLocation = useAppSelector(
    (state) => state.busAttendances.selectedLocation
  );

  const selectedStopDuration = useMemo(() => {
    if (!selectedLocation) {
      return "";
    }

    const diff = selectedLocation.endedAt - selectedLocation.startedAt;
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    const hoursTxt = hours ? `${hours}hr ` : "";
    const minutesTxt = minutes ? `${minutes}min ` : "";
    const secondsTxt = seconds ? `${seconds}sec` : "";

    return `${hoursTxt}${minutesTxt}${secondsTxt}`;
  }, [selectedLocation]);

  if (!selectedLocation) {
    return null;
  }

  return (
    <Row>
      <InfoItem
        title="Started At"
        info={new Date(selectedLocation.startedAt).toLocaleTimeString()}
      />
      <InfoItem
        title="Ended At"
        info={new Date(selectedLocation.endedAt).toLocaleTimeString()}
      />
      <InfoItem title="Duration" info={selectedStopDuration} />
      {[...selectedLocation.children]
        .sort((a, b) => (!a.time || !b.time ? 1 : a.time > b.time ? 1 : -1))
        .map((child) => (
          <ChildCard key={child.id} child={child} />
        ))}
    </Row>
  );
};

export default SelectedLocationDetails;
