import { useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import Col from "react-bootstrap/Col";
import Controls from "../components/Controls";
import BusMarker from "../components/markers/BusMarker";
import BusStopMarker from "../components/markers/BusStopMarker";
import PickedUpChildrenMarker from "../components/markers/PickedUpChildrenMarker";
import ChildMarker from "../components/markers/ChildMarker";
import SelectedLocationDetails from "../components/SelectedLocationDetails";
import BusDetails from "../components/BusDetails";
import SelectedChildrenDetails from "../components/SelectedChildrenDetails";
import SelectedBusAttendanceDetails from "../components/SelectedBusAttendanceDetails";
import useFetchData from "../hooks/useFetchData";
import useMapHelper from "../hooks/useMapHelper";
import useAppSelector from "../hooks/useAppSelector";
import useAppDispatch from "../hooks/useAppDispatch";
import useRestrictUrlAccess from "../hooks/useRestrictUrlAccess";
import useRecordStats from "../hooks/useRecordStats";
import {
  setSelectedBus,
  setSelectedLocation,
} from "../redux/module/busAttendances";
import { selectRoundedAndSortedLocations } from "../redux/module/locations";
import { isSameLocation } from "../services/location.service";
import { wait } from "../utils";
import * as mapStyles from "../utils/mapStyles";

import { DetailedLocation, LatLng } from "../types";
import { useNavigate } from "react-router-dom";

const thirtyDaysMillis = 30 * 24 * 60 * 60 * 1000;

type CacheItem = {
  origin: DetailedLocation;
  destination: DetailedLocation;
  fetchedDate: string; // TODO: fetched date
  overviewPath: LatLng[];
};

let directionsQueryLimit = 10;
const LocationTracking = () => {
  const navigate = useNavigate();
  useRestrictUrlAccess();
  const { handleRefresh } = useFetchData();
  const dispatch = useAppDispatch();
  const { isLoaded, map, onLoad, onUnmount, centerMap, showMapChildren } =
    useMapHelper();
  const locations = useAppSelector((state) => state.locations.locations);
  const latestLocations = useAppSelector(
    (state) => state.locations.latestLocations
  );
  const busAttendances = useAppSelector(
    (state) => state.busAttendances.busAttendances
  );
  const selectedBusAttendance = useAppSelector(
    (state) => state.busAttendances.selectedBusAttendance
  );
  const selectedBus = useAppSelector(
    (state) => state.busAttendances.selectedBus
  );
  const showBusStops = useAppSelector((state) => state.controls.showBusStops);
  const showPickedUp = useAppSelector((state) => state.controls.showPickedUp);
  const selectedLocation = useAppSelector(
    (state) => state.busAttendances.selectedLocation
  );
  const selectedChildren = useAppSelector(
    (state) => state.busAttendances.selectedChildren
  );
  const roundedLocations = useAppSelector(selectRoundedAndSortedLocations);
  const renderedPolylineLocationsRef = useRef<DetailedLocation[]>([]);
  const [polylineLocations, setPolylineLocations] =
    useState<DetailedLocation[]>(roundedLocations);
  const recordStats = useRecordStats();

  useEffect(() => {
    if (
      roundedLocations.every(
        (item, index) =>
          item.lat === renderedPolylineLocationsRef.current[index]?.lat &&
          item.lng === renderedPolylineLocationsRef.current[index]?.lng
      )
    ) {
      return;
    }

    setPolylineLocations(roundedLocations);
    renderedPolylineLocationsRef.current = roundedLocations;
  }, [roundedLocations, renderedPolylineLocationsRef]);

  useEffect(() => {
    if (
      !map ||
      !isLoaded ||
      !polylineLocations.length ||
      !selectedBusAttendance
    ) {
      return;
    }
    const service = new google.maps.DirectionsService();
    const path = new google.maps.MVCArray();
    const poly = new google.maps.Polyline({
      map,
      strokeColor: "#12a3ff",
      zIndex: 1,
      strokeWeight: 4,
      strokeOpacity: 0.95,
      path,
    });

    const getRouteAsync = (request: google.maps.DirectionsRequest) =>
      new Promise<google.maps.LatLng[] | undefined>((resolve) => {
        service.route(request, function (result) {
          if (result?.routes[0].overview_path) {
            recordStats({ requested: 1, succeeded: 1 });
          } else {
            recordStats({ requested: 1, requestFailedWithNoData: 1 });
          }
          resolve(result?.routes[0].overview_path);
        });
      });

    const cachedOverviewPathsString = localStorage.getItem("overview_path");

    let cachedOverviewPaths = [] as Array<CacheItem>;
    try {
      if (cachedOverviewPathsString) {
        cachedOverviewPaths = JSON.parse(cachedOverviewPathsString);
      }
    } catch (err) {}

    const findCachedItem = (
      origin: DetailedLocation,
      destination: DetailedLocation
    ) => {
      if (cachedOverviewPathsString) {
        const existingItem = cachedOverviewPaths.find(
          (item) =>
            isSameLocation(origin, item.origin) &&
            isSameLocation(destination, item.destination)
        );
        if (existingItem?.overviewPath) {
          if (
            new Date(existingItem.fetchedDate).getTime() >
            Date.now() - thirtyDaysMillis
          ) {
            recordStats({ requested: 1, succeeded: 1, readFromCache: 1 });
            return existingItem.overviewPath.map((item) => ({
              ...item,
              lat() {
                return item.lat;
              },
              lng() {
                return item.lng;
              },
            }));
          } else {
            const existingItemIndex = cachedOverviewPaths.indexOf(existingItem);
            cachedOverviewPaths = cachedOverviewPaths.filter(
              (_, index) => index !== existingItemIndex
            );
            localStorage.setItem(
              "overview_path",
              JSON.stringify(cachedOverviewPaths)
            );
          }
        }
      }
    };

    const saveCachedItem = (cacheItem: CacheItem) => {
      cachedOverviewPaths.push(cacheItem);
      localStorage.setItem(
        "overview_path",
        JSON.stringify(cachedOverviewPaths)
      );
    };

    async function createPoly() {
      for (const index in polylineLocations) {
        const item = polylineLocations[index];
        const nextItem = polylineLocations[+index + 1];

        if (!nextItem) {
          continue;
        }
        let overviewPath = findCachedItem(item, nextItem);
        if (!overviewPath) {
          directionsQueryLimit -= 1;
          if (directionsQueryLimit <= 0) {
            await wait(1100);
          }
          overviewPath = await getRouteAsync({
            origin: item,
            destination: nextItem,
            travelMode: google.maps.TravelMode.DRIVING,
          });
          if (overviewPath) {
            saveCachedItem({
              origin: item,
              destination: nextItem,
              overviewPath: overviewPath.map((item) => ({
                lat: item.lat(),
                lng: item.lng(),
              })),
              fetchedDate: new Date().toISOString(),
            });
          }
        }
        for (const item of overviewPath || []) {
          path.push(item);
        }
      }
    }
    createPoly();
    return () => {
      poly.setMap(null);
    };
  }, [map, isLoaded, polylineLocations, selectedBusAttendance]);

  useEffect(() => {
    if (!isLoaded || !(latestLocations.length || locations.length)) {
      return;
    }
    centerMap(locations.length ? locations : latestLocations);
  }, [isLoaded, latestLocations, locations, centerMap]);

  useEffect(() => {
    if (!selectedChildren.length) {
      return;
    }
    centerMap(
      selectedChildren
        .map((child) => ({
          lat: +child.location.latitude,
          lng: +child.location.longitude,
        }))
        .filter(Boolean) as Array<LatLng>
    );
  }, [selectedChildren, centerMap]);

  const handleBusMarkerClick = (busAttendanceId: string) => {
    const newSelectedBus = busAttendances.find(
      (i) => i.busAttendanceId === busAttendanceId
    );

    dispatch(
      setSelectedBus(
        (busAttendanceId !== selectedBus?.busAttendanceId && newSelectedBus) ||
          null
      )
    );
  };

  const { busStops, pickedUpChildren, notRenderedLocations } = useMemo(() => {
    const pickedUpChildren = [];
    const busStops = [];
    const notRenderedLocations = [];

    for (const l of roundedLocations) {
      const selectedChild = selectedChildren.find((child) =>
        isSameLocation(l, {
          lat: +child.location.latitude,
          lng: +child.location.longitude,
        })
      );
      if (selectedChild) {
        notRenderedLocations.push(l);
      } else if (showPickedUp && l.children.length) {
        pickedUpChildren.push(l);
      } else if (showBusStops && l.isStop) {
        busStops.push(l);
      }
    }

    return { pickedUpChildren, busStops, notRenderedLocations };
  }, [roundedLocations, showPickedUp, showBusStops, selectedChildren]);

  return (
    <div className="w-100 h-100 p-2 d-flex">
      <Col
        xxl={4}
        lg={4}
        md={4}
        sm={4}
        xs={24}
        style={{ overflowX: "hidden", overflowY: "auto", position: "relative" }}
        className="pe-2"
      >
        <Controls onRefresh={handleRefresh} />
        <BusDetails busAttendance={selectedBusAttendance || selectedBus} />
        <SelectedBusAttendanceDetails />
        <SelectedLocationDetails />
        <SelectedChildrenDetails />
        {/* <div
          className="text-secondary"
          onClick={() => navigate("/stats")}
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
            cursor: "pointer",
          }}
        >
          <small>stats</small>
        </div> */}
      </Col>
      <Col xxl={8} lg={8} md={8} sm={8} xs={24}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "calc(100vh - 16px)" }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              mapTypeControlOptions: { mapTypeIds: [] },
              streetViewControl: false,
              styles: mapStyles.silver,
              maxZoom: 17,
              minZoom: 10,
            }}
          >
            {showMapChildren && (
              <>
                {selectedChildren.map((child) => (
                  <ChildMarker
                    key={child.id}
                    notOnBus={child.notOnBus}
                    onClick={() => {
                      const childLocation = notRenderedLocations.find((l) =>
                        isSameLocation(
                          {
                            lat: +child.location.latitude,
                            lng: +child.location.longitude,
                          },
                          l
                        )
                      );
                      if (childLocation) {
                        dispatch(setSelectedLocation(childLocation));
                      }
                    }}
                    position={{
                      lat: +child.location.latitude,
                      lng: +child.location.longitude,
                    }}
                  />
                ))}
                {selectedBusAttendance ? (
                  <>
                    {pickedUpChildren.map((l) => (
                      <PickedUpChildrenMarker
                        key={l.index}
                        position={l}
                        onClick={() => dispatch(setSelectedLocation(l))}
                        isSelected={selectedLocation?.index === l.index}
                      />
                    ))}
                    {busStops.map((l) => (
                      <BusStopMarker
                        key={l.index}
                        stopPoint={l}
                        onClick={() => dispatch(setSelectedLocation(l))}
                        isSelected={selectedLocation?.index === l.index}
                      />
                    ))}
                  </>
                ) : (
                  latestLocations.map((l) => (
                    <BusMarker
                      key={l.id}
                      position={l}
                      onClick={() => handleBusMarkerClick(l.busAttendanceId)}
                      isSelected={
                        selectedBus?.busAttendanceId === l.busAttendanceId
                      }
                    />
                  ))
                )}
              </>
            )}
          </GoogleMap>
        )}
      </Col>
    </div>
  );
};

export default LocationTracking;
