import Row from "react-bootstrap/Row";
import InfoItem from "./InfoItem";
import { directionText } from "../constants";

import type { BusAttendance } from "../types";

type BusDetailsProps = {
  busAttendance: BusAttendance | null;
};

const BusDetails = (props: BusDetailsProps) => {
  if (!props.busAttendance) {
    return null;
  }

  return (
    <Row>
      <InfoItem title="Bus name" info={props.busAttendance.busName} />
      {props.busAttendance.scheduleDirection && (
        <InfoItem
          title="Direction"
          info={directionText[props.busAttendance.scheduleDirection]}
        />
      )}
    </Row>
  );
};

export default BusDetails;
