import { useEffect } from "react";
import useAppDispatch from "./useAppDispatch";
import useAppSelector from "./useAppSelector";
import {
  getBusAttendances,
  getChildrenWithBusAttendance,
  getChildrenNotOnBus,
} from "../redux/module/busAttendances";
import {
  getBusAttendanceLocations,
  getLatestLocation,
} from "../redux/module/locations";

function useFetchData() {
  const dispatch = useAppDispatch();
  const date = useAppSelector((state) => state.controls.date);
  const direction = useAppSelector((state) => state.controls.direction);
  const selectedBusAttendance = useAppSelector(
    (state) => state.busAttendances.selectedBusAttendance
  );

  useEffect(() => {
    dispatch(getChildrenWithBusAttendance({ date, direction }));
    dispatch(getChildrenNotOnBus({ date, direction }));
  }, [dispatch, date, direction]);

  useEffect(() => {
    dispatch(getBusAttendances(date));
  }, [dispatch, date]);

  useEffect(() => {
    if (!selectedBusAttendance) {
      return;
    }
    dispatch(getBusAttendanceLocations(selectedBusAttendance.busAttendanceId));
  }, [dispatch, selectedBusAttendance]);

  useEffect(() => {
    dispatch(getLatestLocation());
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(getBusAttendances(date));
    dispatch(getChildrenWithBusAttendance({ date, direction }));
    dispatch(getChildrenNotOnBus({ date, direction }));
    dispatch(getLatestLocation());
    if (selectedBusAttendance) {
      dispatch(
        getBusAttendanceLocations(selectedBusAttendance.busAttendanceId)
      );
    }
  };

  return { handleRefresh };
}

export default useFetchData;
