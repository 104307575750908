import React from "react";

type BaseIconProps = React.SVGProps<SVGSVGElement> & { size?: number };

export type SVGIconProps = Omit<BaseIconProps, "children">;

/**
 * Designed for using with [bootstrap icons](https://icons.getbootstrap.com/)
 * @example
 * const SomeIcon = (props: SVGIconProps) => (
 *   <BaseIcon {...props}>
 *     <path d="..." />
 *   </BaseIcon>
 * );
 */
const BaseIcon = ({ size = 16, ...props }: BaseIconProps) => (
  <svg
    width={size}
    height={size}
    fill="currentColor"
    viewBox="0 0 16 16"
    {...props}
  />
);

export default BaseIcon;
