import { useCallback, useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../constants";

import type { LatLng } from "../types";

const center = [
  {
    lat: 40.8236528,
    lng: -74.1250567,
  },
  {
    lat: 40.6955502,
    lng: -73.9445643,
  },
];

function useMapHelper() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [showMapChildren, setShowMapChildren] = useState(false);

  /**
   * NOTE: rendering map children immediately after the map was loaded
   * may lead to bugs, such as not showing them until next rerender.
   * This logic helps avoiding them
   */
  useEffect(() => {
    if (!isLoaded || showMapChildren) {
      return;
    }
    setTimeout(() => setShowMapChildren(true), 1000);
  }, [isLoaded, showMapChildren]);

  const onLoad = useCallback((map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds(center[0]);
    bounds.extend(new window.google.maps.LatLng(center[1].lat, center[1].lng));
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(() => setMap(null), []);

  const centerMap = useCallback(
    (newMarkers: Array<LatLng>) => {
      try {
        const bounds = new google.maps.LatLngBounds();
        if (!map) {
          return;
        }

        for (const { lat, lng } of newMarkers) {
          bounds.extend(new google.maps.LatLng(lat, lng));
        }

        map.fitBounds(bounds, 0);
      } catch (err) {
        console.error("render marker error", err);
      }
    },
    [map]
  );

  return { isLoaded, map, onLoad, onUnmount, centerMap, showMapChildren };
}

export default useMapHelper;
