import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function useRestrictUrlAccess() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.self === window.top) {
      navigate("/not-allowed");
    }
  }, [navigate]);
}
