import Row from "react-bootstrap/Row";
import InfoItem from "./InfoItem";
import useAppSelector from "../hooks/useAppSelector";
import { BusAttendanceStatus } from "../types";

const statusText = {
  [BusAttendanceStatus.Started]: "Started",
  [BusAttendanceStatus.Cancelled]: "Cancelled",
  [BusAttendanceStatus.Completed]: "Completed",
};

const SelectedBusAttendanceDetails = () => {
  const selectedBusAttendance = useAppSelector(
    (state) => state.busAttendances.selectedBusAttendance
  );

  if (!selectedBusAttendance) {
    return null;
  }

  return (
    <Row>
      {selectedBusAttendance.status && (
        <InfoItem
          title="Status"
          info={statusText[selectedBusAttendance.status]}
        />
      )}
    </Row>
  );
};

export default SelectedBusAttendanceDetails;
