import { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import {
  getAppInfoFromFDB,
  getLocations,
  getStatsFromFDB,
} from "../services/firebase-db.service";
import { AppInfoItem, Location, StatsItem } from "../types";

// Anomalies
// 4992
// 4997

type InfoByStage = Partial<
  Record<AppInfoItem["coll"], { [version: string]: Array<AppInfoItem> }>
>;

const Stats = () => {
  // const [locations, setLocations] = useState<Array<Location>>([]);
  const [stats, setStats] = useState<Array<StatsItem>>([]);
  const [infoByStage, setInfoByStage] = useState<InfoByStage>({});

  // const latestStat = [...stats].sort((a, b) =>
  //   a.startTimestamp > b.startTimestamp ? -1 : 1
  // )[0];
  // console.log(latestStat, "latestStat");

  useEffect(() => {
    // getLocations({ busAttendanceId: "4997" }).then(setLocations);
    getStatsFromFDB().then(setStats);
    getAppInfoFromFDB().then((res) => {
      const sortedByVersion = [...res].sort((a, b) =>
        a.version > b.version ? 1 : -1
      );
      const uniqueByUserIdAndStage: Array<AppInfoItem> = [];
      for (const item of sortedByVersion) {
        if (
          uniqueByUserIdAndStage.some(
            (i) => i.coll === item.coll && i.userId === item.userId
          )
        ) {
          continue;
        }
        uniqueByUserIdAndStage.push(item);
      }
      const formattedResult = uniqueByUserIdAndStage.reduce<InfoByStage>(
        (acc, item) => {
          if (!acc[item.coll]) {
            acc[item.coll] = {
              [item.version]: [],
            };
          }
          acc[item.coll]?.[item.version].push(item);

          return acc;
        },
        {}
      );
      setInfoByStage(formattedResult);
    });
  }, []);
  console.log(infoByStage, "infoByStage");

  const { mobileStatsSum, webStatsSum } = useMemo(
    () => ({
      mobileStatsSum: stats
        .filter((stat) => stat.source === "mobile")
        .reduce(
          (acc, item) => {
            acc.total += item.requested;
            acc.succeeded += item.succeeded + item.requestFailedWithNoData;
            acc.readFromCache += item.readFromCache;
            acc.failed += item.requestFailed - item.requestFailedWithNoData;
            acc.noData += item.requestFailedWithNoData;

            return acc;
          },
          {
            total: 0,
            succeeded: 0,
            readFromCache: 0,
            failed: 0,
            noData: 0,
          }
        ),
      webStatsSum: stats
        .filter((stat) => stat.source === "web")
        .reduce(
          (acc, item) => {
            acc.total += item.requested;
            acc.succeeded += item.succeeded + item.requestFailedWithNoData;
            acc.readFromCache += item.readFromCache;
            acc.failed += item.requestFailed - item.requestFailedWithNoData;
            acc.noData += item.requestFailedWithNoData;

            return acc;
          },
          {
            total: 0,
            succeeded: 0,
            readFromCache: 0,
            failed: 0,
            noData: 0,
          }
        ),
    }),
    [stats]
  );

  return (
    <Container className="h-100">
      {/* <InfoItem title="Locations count">{locations.length}</InfoItem> */}
      {Object.keys(mobileStatsSum).map((key) => (
        <InfoItem key={key} title={`Mobile Requests ${key}`}>
          {mobileStatsSum[key as keyof typeof mobileStatsSum]}
        </InfoItem>
      ))}
      {Object.keys(webStatsSum).map((key) => (
        <InfoItem key={key} title={`Web Requests ${key}`}>
          {webStatsSum[key as keyof typeof webStatsSum]}
        </InfoItem>
      ))}
    </Container>
  );
};

type InfoItemProps = {
  title: string;
  children: React.ReactNode;
};

const InfoItem = (props: InfoItemProps) => (
  <div className="mb-2">
    {props.title}: {props.children}
  </div>
);

export default Stats;
