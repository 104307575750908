import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_API_URL } from "../../constants";

import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  BusAttendance,
  ChildData,
  DetailedLocation,
  Direction,
} from "../../types";

const queryParams = new URLSearchParams(window.location.search);

const user_id = queryParams.get("user_id") as string;
const auth_token = queryParams.get("auth_token") as string;

const formatDateForQuery = (date: Date | string) =>
  new Date(date).toISOString().substring(0, 10);

const formatBusAttendanceItem = (busAttendance: any) => ({
  busAttendanceId: busAttendance.bus_attendance_id,
  busRoute: busAttendance.bus_route,
  busName: busAttendance.bus_name,
  profilePicture: busAttendance.profile_picture,
  scheduleDirection: busAttendance.schedule_direction,
  status: busAttendance.status,
  startedAt: busAttendance.started_at,
  completedAt: busAttendance.completed_at,
  busAttendant: busAttendance.bus_attendant,
  busDriver: busAttendance.bus_driver,
  location: busAttendance.location,
});

const formatChildItem = (childItem: any) => {
  const {
    parent_email: parentEmail,
    nfc_card_uid: nfcCardUid,
    class_teacher: classTeacher,
    profile_picture: profilePicture,
    ...child
  } = childItem.child;

  return {
    ...child,
    parentEmail,
    nfcCardUid,
    classTeacher,
    profilePicture,
    busAttendanceId:
      childItem.bus_attendance?.bus_attendance_id ||
      childItem.bus_attendance_id,
    name: `${child.fname} ${child.lname}`,
    notOnBus: !child.bus_attendance,
    ...(childItem.bus_attendance || { date: childItem.date }),
    ...(child.schedule
      ? {
          location: {
            latitude: child.schedule.scheduled_latitude || child.home.latitude,
            longitude:
              child.schedule.scheduled_longitude || child.home.longitude,
            address: child.schedule.scheduled_address || child.home.address,
          },
        }
      : {}),
  };
};

export interface BusAttendancesState {
  busAttendances: Array<BusAttendance>;
  busAttendancesLoading: boolean;
  selectedBusAttendance: BusAttendance | null;
  selectedBusAttendanceChildren: Array<ChildData>;
  childrenWithBusAttendance: Array<ChildData>;
  childrenWithBusAttendanceLoading: boolean;
  childrenNotOnBus: Array<ChildData>;
  childrenNotOnBusLoading: boolean;
  selectedLocation: DetailedLocation | null;
  selectedBus: BusAttendance | null;
  selectedChildren: Array<ChildData>;
}

const initialState: BusAttendancesState = {
  busAttendances: [],
  busAttendancesLoading: false,
  selectedBusAttendance: null,
  selectedBusAttendanceChildren: [],
  childrenWithBusAttendance: [],
  childrenWithBusAttendanceLoading: false,
  childrenNotOnBus: [],
  childrenNotOnBusLoading: false,
  selectedLocation: null,
  selectedBus: null,
  selectedChildren: [],
};

export const getBusAttendances = createAsyncThunk(
  "busAttendances/getBusAttendances",
  async (date: string) => {
    const reportData = new FormData();

    reportData.append("date", formatDateForQuery(date));
    reportData.append("user_id", user_id);
    reportData.append("auth_token", auth_token);

    const response = await fetch(`${BASE_API_URL}/bus_routes/report`, {
      method: "POST",
      body: reportData,
    });

    return response.json();
  }
);

export const getChildrenWithBusAttendance = createAsyncThunk(
  "busAttendances/getChildrenWithBusAttendance",
  async ({ date, direction }: { date: string; direction: Direction }) => {
    const children_on_busData = new FormData();

    children_on_busData.append("date", formatDateForQuery(date));
    children_on_busData.append("user_id", user_id);
    children_on_busData.append("auth_token", auth_token);
    children_on_busData.append("direction", direction);

    const response = await fetch(`${BASE_API_URL}/bus_routes/children_on_bus`, {
      method: "POST",
      body: children_on_busData,
    });
    return response.json();
  }
);

export const getChildrenNotOnBus = createAsyncThunk(
  "busAttendances/getChildrenNotOnBus",
  async ({ date, direction }: { date: string; direction: Direction }) => {
    const children_not_on_busData = new FormData();

    children_not_on_busData.append("date", formatDateForQuery(date));
    children_not_on_busData.append("user_id", user_id);
    children_not_on_busData.append("auth_token", auth_token);
    children_not_on_busData.append("direction", direction);

    const response = await fetch(
      `${BASE_API_URL}/bus_routes/children_not_on_bus`,
      {
        method: "POST",
        body: children_not_on_busData,
      }
    );
    return response.json();
  }
);

export const busAttendancesSlice = createSlice({
  name: "busAttendances",
  initialState,
  reducers: {
    setSelectedBusAttendance: (
      state,
      action: PayloadAction<BusAttendancesState["selectedBusAttendance"]>
    ) => {
      state.selectedBusAttendance = action.payload;
      state.selectedBusAttendanceChildren =
        state.childrenWithBusAttendance.filter(
          (item) => item.busAttendanceId === action.payload?.busAttendanceId
        );
      state.selectedLocation = null;
    },
    setSelectedLocation: (
      state,
      action: PayloadAction<BusAttendancesState["selectedLocation"]>
    ) => {
      state.selectedLocation = action.payload;
      state.selectedBus = null;
    },
    setSelectedBus: (
      state,
      action: PayloadAction<BusAttendancesState["selectedBus"]>
    ) => {
      state.selectedBus = action.payload;
      state.selectedLocation = null;
    },
    setSelectedChildren: (
      state,
      action: PayloadAction<BusAttendancesState["selectedChildren"]>
    ) => {
      state.selectedChildren = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusAttendances.pending, (state) => {
        state.busAttendancesLoading = true;
      })
      .addCase(getBusAttendances.fulfilled, (state, action) => {
        state.busAttendancesLoading = false;
        state.busAttendances = (action.payload?.data || []).map(
          formatBusAttendanceItem
        );
      })
      .addCase(getBusAttendances.rejected, (state) => {
        state.busAttendancesLoading = false;
      })
      .addCase(getChildrenWithBusAttendance.pending, (state) => {
        state.childrenWithBusAttendanceLoading = true;
      })
      .addCase(getChildrenWithBusAttendance.fulfilled, (state, action) => {
        state.childrenWithBusAttendanceLoading = false;
        if (Array.isArray(action.payload.data)) {
          state.childrenWithBusAttendance =
            action.payload.data.map(formatChildItem);
        }
      })
      .addCase(getChildrenWithBusAttendance.rejected, (state) => {
        state.childrenWithBusAttendanceLoading = false;
      })
      .addCase(getChildrenNotOnBus.pending, (state) => {
        state.childrenNotOnBusLoading = true;
        state.childrenNotOnBus = [];
      })
      .addCase(getChildrenNotOnBus.fulfilled, (state, action) => {
        state.childrenNotOnBusLoading = false;
        if (Array.isArray(action.payload.data)) {
          state.childrenNotOnBus = action.payload.data.map(formatChildItem);
        }
      })
      .addCase(getChildrenNotOnBus.rejected, (state) => {
        state.childrenNotOnBusLoading = false;
      });
  },
});

export const {
  setSelectedBusAttendance,
  setSelectedLocation,
  setSelectedBus,
  setSelectedChildren,
} = busAttendancesSlice.actions;

export default busAttendancesSlice.reducer;
