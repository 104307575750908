export function keyBy<T extends Record<any, any>>(
  collection: Array<T>,
  iterator: keyof T | ((item: T) => string)
) {
  const map: Record<string, T> = {};
  collection.forEach((item) => {
    const key =
      typeof iterator === "function" ? iterator(item) : item[iterator];
    map[key] = item;
  });
  return map;
}

export function groupBy<T extends Record<any, any>>(
  collection: Array<T>,
  iterator: keyof T | ((item: T) => string)
) {
  const map: Record<string, Array<T>> = {};
  collection.forEach((item) => {
    const key =
      typeof iterator === "function" ? iterator(item) : item[iterator];
    if (!map[key]) {
      map[key] = [];
    }
    map[key].push(item);
  });
  return map;
}

export const colors = {
  blue1: "#00738D",
  blue2: "#0C96B6",
  blue3: "#209EBC",
  blue4: "#52B4CB",
  blue5: "#10D9B9",
  blue6: "#0F839E",
  blue7: "#E6FCF8",
  pink1: "#F178A6",
  pink2: "#E0759E",
  pink3: "#FFA4C7",
  pink4: "#FFE9F1",
  gray1: "#C7C7C7",
  gray2: "#838383",
  gray3: "#E3E3E3",
  gray4: "#F5F5F5",
  gray5: "#D9D9D9",
  gray6: "#ECECEC",
  gray7: "#F0F0F0",
  gray8: "#4C4C4C",
  gray9: "#EFEFEF",
  gray10: "#F5F3F4",
  red1: "#FF1818",
  red2: "#CE5050",
  red3: "#FBEBED",
};

export const wait = (
  /** Default: 1000 */
  ms = 1000
) => new Promise((res) => setTimeout(res, ms));
