import type { ReactNode } from "react";

type InfoItemProps = {
  title: ReactNode;
  info: ReactNode;
};

const InfoItem = (props: InfoItemProps) => (
  <div className="text-break">
    <span className="text-nowrap">{props.title}:</span>
    <b className="ps-1 text-nowrap">{props.info}</b>
  </div>
);

export default InfoItem;
