import {
  addDoc,
  getDocs,
  query,
  collection,
  limit,
  where,
  QueryConstraint,
  orderBy,
  OrderByDirection,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  FIREBASE_LOCATION_COLLECTION,
  REACT_APP_FIREBASE_STATS_COLLECTION,
} from "../constants";

import type { CollectionReference, DocumentData } from "firebase/firestore";
import type { AppInfoItem, Location, StatsItem } from "../types";

function post<D extends DocumentData = DocumentData>(
  path: string,
  data: Omit<D, "id">
) {
  return addDoc(collection(db, path), data);
}

async function get<D>(
  path: string,
  ...queryConstraints: Array<QueryConstraint>
) {
  const q = query(
    collection(db, path) as CollectionReference<D>,
    ...queryConstraints
  );
  const result = await getDocs(q);
  return result.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

export function getLocations(params?: {
  busAttendanceId?: string;
  busAttendanceIds?: Array<string>;
  limit?: number;
  sort?: { [key in keyof Location]?: OrderByDirection };
  timestamp?: { "<="?: number; ">="?: number };
}) {
  const queryConstraints: Array<QueryConstraint> = [];

  if (params?.busAttendanceId) {
    queryConstraints.push(
      where("busAttendanceId", "in", [
        Number(params.busAttendanceId),
        String(params.busAttendanceId),
      ])
    );
  }
  if (params?.busAttendanceIds) {
    queryConstraints.push(
      where("busAttendanceId", "in", [
        ...params.busAttendanceIds.map(Number),
        ...params.busAttendanceIds.map(String),
      ])
    );
  }
  if (params?.limit) {
    queryConstraints.push(limit(params.limit));
  }
  if (params?.timestamp) {
    Object.entries(params.timestamp).forEach(([opStr, value]) => {
      queryConstraints.push(
        where("timestamp", opStr as keyof typeof params.timestamp, value)
      );
    });
  }

  if (params?.sort) {
    Object.entries(params.sort).map(([key, direction]) =>
      queryConstraints.push(orderBy(key, direction))
    );
  }

  return get<Location>(FIREBASE_LOCATION_COLLECTION, ...queryConstraints);
}

export const postStatsToFDB = (data: any) =>
  post("stats", {
    ...data,
    source: "web",
    coll: REACT_APP_FIREBASE_STATS_COLLECTION,
  });

export const getStatsFromFDB = () =>
  get<StatsItem>("stats", where("coll", "==", "stats-prod"));

export const getAppInfoFromFDB = () => get<AppInfoItem>("app-info");
